import React, { useState } from 'react'
import type { CSSProperties } from 'react'

import Star from './Star'

type StarRatingsProps = {
  rating: number
  numberOfStars?: number
  changeRating?: (...args: any[]) => void
  starHoverColor?: string
  starRatedColor?: string
  starEmptyColor?: string
  starDimension?: string
  starSpacing?: string
  name?: string
  uniqueId: string
  onHover?: (starRating: number) => void
}

const starRatingsStyle: CSSProperties = {
  position: 'relative',
  boxSizing: 'border-box',
  display: 'inline-block',
}

const starGradientStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 0,
  width: 0,
  height: 0,
  visibility: 'hidden',
}

const stopColorStyle = (color: string): CSSProperties => ({
  stopColor: color,
  stopOpacity: 1,
})

const StarRatings: React.FC<StarRatingsProps> = ({
  rating = 0,
  numberOfStars = 5,
  changeRating,
  starHoverColor = 'rgb(230, 67, 47)',
  starRatedColor = 'rgb(109, 122, 130)',
  starEmptyColor = 'rgb(203, 211, 227)',
  starDimension = '50px',
  starSpacing = '7px',
  name,
  uniqueId,
  onHover,
}) => {
  const fillId = `starGrad-${uniqueId}`
  const [state, setState] = useState({
    highestStarHovered: -Infinity,
  })

  const titleText = () => {
    const hoveredRating = state.highestStarHovered
    const currentRating = hoveredRating > 0 ? hoveredRating : rating
    // fix it at 2 decimal places and remove trailing 0s
    let formattedRating = parseFloat(currentRating.toFixed(2)).toString()
    if (Number.isInteger(currentRating)) {
      formattedRating = String(currentRating)
    }
    let starText = `Stars`
    if (formattedRating === '1') {
      starText = 'Star'
    }
    return `${formattedRating} ${starText}`
  }

  const offsetValue = () => {
    const ratingIsInteger = Number.isInteger(rating)
    let offsetValue = '0%'
    if (!ratingIsInteger) {
      const firstTwoDecimals = rating.toFixed(2).split('.')[1].slice(0, 2)
      offsetValue = `${firstTwoDecimals}%`
    }
    return offsetValue
  }

  const hoverOverStar = (starRating: number) => () => {
    setState({
      highestStarHovered: starRating,
    })
    onHover?.(starRating)
  }

  const unHoverOverStar = () => {
    setState({
      highestStarHovered: -Infinity,
    })
    onHover?.(-Infinity)
  }

  const renderStars = () => {
    const numberOfStarsArray = new Array(numberOfStars).fill(null)

    return numberOfStarsArray.map((_, index) => {
      const starRating = index + 1
      const isStarred = starRating <= rating

      // hovered only matters when changeRating is true
      const hoverMode = state.highestStarHovered > 0
      const isHovered = starRating <= state.highestStarHovered
      const isCurrentHoveredStar = starRating === state.highestStarHovered

      // only matters when changeRating is false
      // given star 5 and rating 4.2:  5 > 4.2 && 4 < 4.2;
      const isPartiallyFullStar = starRating > rating && starRating - 1 < rating

      const isFirstStar = starRating === 1
      const isLastStar = starRating === numberOfStars

      return (
        <Star
          changeRating={
            changeRating ? () => changeRating(starRating, name) : undefined
          }
          fillId={fillId}
          gradientPathName={''}
          hoverMode={hoverMode}
          hoverOverStar={changeRating ? hoverOverStar(starRating) : undefined}
          isCurrentHoveredStar={isCurrentHoveredStar}
          isFirstStar={isFirstStar}
          isHovered={isHovered}
          isLastStar={isLastStar}
          isPartiallyFullStar={isPartiallyFullStar}
          isStarred={isStarred}
          key={starRating}
          starDimension={starDimension}
          starEmptyColor={starEmptyColor}
          starHoverColor={starHoverColor}
          starRatedColor={starRatedColor}
          starSpacing={starSpacing}
          svgIconPath={'m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z'}
          svgIconViewBox={'0 0 51 48'}
          unHoverOverStar={changeRating ? unHoverOverStar : undefined}
        />
      )
    })
  }

  return (
    <div className="star-ratings" style={starRatingsStyle} title={titleText()}>
      <svg className="star-grad" style={starGradientStyle}>
        <defs>
          <linearGradient id={fillId} x1="0%" x2="100%" y1="0%" y2="0%">
            <stop
              className="stop-color-first"
              offset="0%"
              style={stopColorStyle(starRatedColor)}
            />
            <stop
              className="stop-color-first"
              offset={offsetValue()}
              style={stopColorStyle(starRatedColor)}
            />
            <stop
              className="stop-color-final"
              offset={offsetValue()}
              style={stopColorStyle(starEmptyColor)}
            />
            <stop
              className="stop-color-final"
              offset="100%"
              style={stopColorStyle(starEmptyColor)}
            />
          </linearGradient>
        </defs>
      </svg>
      {renderStars()}
    </div>
  )
}

export default StarRatings
