import type { ImageLoader } from 'next/image'

import cloudinaryLoader from './cloudinaryLoader'

const base = 'https://d3kpuwe9hkbxpz.cloudfront.net'

const cloudfrontLoader: ImageLoader = ({ src, width, quality }) => {
  // Example "src": "listings_s3//listings/513925/images/main/a0FPl0000028cuzMAA"
  const regex = /listings\/(\d+)\/images\/main\/([a-zA-Z0-9]+)/
  const matches = src.match(regex)
  const listingId = matches?.[1]
  const imageId = matches?.[2]
  const modifiers: string[] = []

  if (listingId && imageId) {
    if (width) {
      modifiers.push(`width=${width}`)
    }

    if (quality) {
      modifiers.push(`quality=${quality}`)
    }

    return `${base}/listings/${listingId}/${imageId}?${modifiers.join('&')}`
  }

  // If we don't recognize the URL, fall back to the classic Cloudinary loader.
  return cloudinaryLoader({ src, width, quality })
}

export default cloudfrontLoader
