import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { toast, cssTransition } from 'react-toastify'
import { useSession } from 'next-auth/react'
import type { Hit } from 'react-instantsearch-core'

import { useDispatch, useSelect } from 'store/index'

import {
  addSelectedListing,
  removeSelectedListing,
  setFirstFavoriteId,
  setShouldRemoveData,
  clearShouldRemoveData,
  setShouldRemoveResigned,
  setShowFavoriteNotification,
  createCollection,
  addFavorite,
  deleteFavorite,
  setCurrentCollectionId,
  addTempListing,
  addSelectedListingFromListingData,
  trackFavoriteEvent,
  setSelectedFavorite,
} from 'reducers/favorites'
import {
  setShowLoginPromptModal,
  setLoginPromptModalOrigin,
} from 'reducers/uiState'

import useTimeout from 'hooks/useTimeout'

import style from './FavoriteButton.module.scss'

import { LargeTabletBreakpoint } from 'config/Breakpoints'

import { pushToDataLayer } from 'utils/Gtm'
import type { CombinedListing } from 'utils/staticData'

import HeartEmpty from 'assets/icons/icon-heartEmpty.svg'
import HeartFilled from 'assets/icons/icon-heartFilled.svg'
import CloseIcon from 'assets/icons/icon-close.svg'

import type { AlgoliaListing } from 'types/externalData'
import type { TrackedEventData } from 'types/segment'

type HitCombinedListing = Hit<AlgoliaListing> | CombinedListing

type FavoriteButtonProps = {
  listing: HitCombinedListing
  container?:
    | 'algoliaResult'
    | 'algoliaMarker'
    | 'mapResult'
    | 'detailOverview'
    | 'resultDetails'
    | 'favoritesPage'
    | 'listingCarousel'
  quoteAvgPrice?: number
}

// Sub-components
const CloseButton = ({ closeToast }: { closeToast: () => void }) => (
  <CloseIcon onClick={closeToast} title="close" />
)

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  listing,
  container,
  quoteAvgPrice,
}) => {
  const { data: session } = useSession()

  const heartTimeout = useTimeout(500)
  const saveTextTimeout = useTimeout(500)

  // Redux actions
  const appDispatch = useDispatch()

  // Redux selectors
  const width = useSelect((state) => state.uiState.width)
  const selectedListings = useSelect(
    (state) => state.favorites.selectedListings,
  )
  const saveAsGuest = useSelect((state) => state.favorites.saveAsGuest)
  const firstFavoriteId = useSelect((state) => state.favorites.firstFavoriteId)
  const selectedListingsData = useSelect(
    (state) => state.favorites.selectedListingsData,
  )
  const resignedListingData = useSelect(
    (state) => state.favorites.resignedListingData,
  )
  const currentCollectionId = useSelect(
    (state) => state.favorites.currentCollectionId,
  )

  const [isSelected, setIsSelected] = useState(false)

  // Custom button styles and GTM events based on button container
  const containerDeps: {
    containerStyle: string
    displayOption: string
  } = useMemo(() => {
    switch (container) {
      case 'algoliaResult':
        return {
          containerStyle: style.algoliaResult,
          displayOption: 'Search - Results',
        }
      case 'algoliaMarker':
        return {
          containerStyle: style.algoliaMarker,
          displayOption: 'Search - Map',
        }
      case 'mapResult':
        return {
          containerStyle: style.mapResult,
          displayOption: 'Search - Map',
        }
      case 'detailOverview':
        return {
          containerStyle: style.detailOverview,
          displayOption: 'Listing Detail Page',
        }
      case 'resultDetails':
        return {
          containerStyle: style.resultDetails,
          displayOption: 'Listing Detail Page',
        }
      case 'favoritesPage':
        return {
          containerStyle: style.favoritesPage,
          displayOption: 'Favorites Page',
        }
      case 'listingCarousel':
        return {
          containerStyle: style.listingCarousel,
          displayOption: 'Location Landing Page',
        }
      default:
        return {
          containerStyle: '',
          displayOption: '',
        }
    }
  }, [container])

  const persistedFirstFavorite = useMemo(
    () =>
      firstFavoriteId
        ? firstFavoriteId
        : process.browser && localStorage.getItem('firstFavoriteId')
        ? localStorage.getItem('firstFavoriteId')
        : '',
    [firstFavoriteId],
  )

  const eventName = `Product ${
    !isSelected ? 'Added to' : 'Removed from'
  } Wishlist`
  const price = Math.ceil(
    parseInt(quoteAvgPrice ?? listing['Average Per Night']),
  )

  const trackedEventData: TrackedEventData = {
    eventName,
    price,
    initiated_from: containerDeps.displayOption,
    product_id: listing.objectID,
  }

  useEffect(() => {
    if (saveAsGuest || session) {
      setIsSelected(
        [
          ...selectedListings,
          // Include persistedFirstFavorite so firstFavoriteId's listing will be selected directly after login/signup
          ...(persistedFirstFavorite
            ? [{ objectID: persistedFirstFavorite }]
            : []),
        ]?.some((selected) => selected.objectID === listing?.objectID),
      )
    }
  }, [
    selectedListings,
    listing,
    firstFavoriteId,
    saveAsGuest,
    session,
    persistedFirstFavorite,
  ])

  const handleAnimations = useCallback(
    (shouldSave: boolean) => {
      if (!isSelected && shouldSave) {
        heartTimeout.setIsRunning(true)
      }
      if (container === 'detailOverview' && width > 600) {
        saveTextTimeout.setIsRunning(true)
      }
    },
    [container, heartTimeout, isSelected, width, saveTextTimeout],
  )

  const handleToast = useCallback(
    (shouldSave: boolean, objectID: string) => {
      toast.dismiss(`${objectID}-shouldSave-${!shouldSave}`)

      return toast(
        shouldSave ? 'Added to Favorites' : 'Removed from Favorites',
        {
          toastId: `${objectID}-shouldSave-${shouldSave}`,
          className: 'toast',
          bodyClassName: 'toastBody',
          closeButton: CloseButton,
          transition: cssTransition({
            enter: style.slideInUp,
            exit: 'fadeOut',
          }),
          position:
            width <= LargeTabletBreakpoint ? 'bottom-center' : 'bottom-right',
          closeOnClick: false,
        },
      )
    },
    [width],
  )

  const handleLoggedInFavoriting = (
    shouldSave: boolean,
    listing: HitCombinedListing,
  ) => {
    if (listing?.objectID && session) {
      if (shouldSave) {
        // GTM tracking - add favorite
        pushToDataLayer('favoriteListing', {
          listingId: listing.objectID,
          displayOption: containerDeps.displayOption,
          loginStatus: true,
        })
        // Re-add listing from selectedListingsData, don't need to fetch data
        if (
          selectedListingsData.some(
            (selectedData) => selectedData.objectID === listing.objectID,
          )
        ) {
          appDispatch(addSelectedListingFromListingData(listing.objectID))
          appDispatch(
            addFavorite({
              favoritePostData: {
                collectionId: currentCollectionId,
                objectIDs: [listing.objectID],
              },
              session,
              trackedEventData,
            }),
          )

          handleAnimations(shouldSave)
          handleToast(shouldSave, listing.objectID ?? '')
        }

        // Add favorite to state while waiting for api
        appDispatch(
          addSelectedListing({
            objectID: listing.objectID,
            Headline: listing?.Headline ?? '',
            ImageUrl: listing?.ImageUrl ?? '',
          }),
        )
        handleAnimations(shouldSave)

        // API calls
        if (
          currentCollectionId === 'noCollection' ||
          currentCollectionId === ''
        ) {
          appDispatch(
            createCollection({ listingIds: [listing.objectID], session }),
          )
          // Use temp id while waiting for createCollection response
          appDispatch(setCurrentCollectionId('temp'))
        } else if (currentCollectionId === 'temp') {
          // Add id to temp list to fetch after collection id is returned from createCollection
          appDispatch(addTempListing(listing.objectID))
        } else {
          appDispatch(
            addFavorite({
              favoritePostData: {
                collectionId: currentCollectionId,
                objectIDs: [listing.objectID],
              },
              session,
              trackedEventData,
            }),
          )
        }
      } else {
        // Remove if resigned, or remove from saved listings/mark for data removal
        const shouldRemoveData = selectedListingsData.find(
          (selectedListing) => selectedListing?.objectID === listing.objectID,
        )?.shouldRemoveData

        const resigned = resignedListingData.find(
          (resignedListing) => resignedListing?.objectID === listing.objectID,
        )

        if (resigned) {
          appDispatch(setShouldRemoveResigned(resigned.objectID))
        }

        appDispatch(removeSelectedListing(listing.objectID))

        if (!shouldRemoveData) {
          appDispatch(setShouldRemoveData(listing.objectID))
        }
        appDispatch(
          deleteFavorite({
            favoritePostData: {
              collectionId: currentCollectionId,
              objectIDs: [listing.objectID],
            },
            session,
            trackedEventData,
          }),
        )
      }

      handleToast(shouldSave, listing.objectID ?? '')
    }
  }

  const handleGuestFavoriting = useCallback(
    (shouldSave: boolean, listing: HitCombinedListing) => {
      if (listing?.objectID) {
        const shouldRemoveData = selectedListingsData.find(
          (selectedListing) => selectedListing?.objectID === listing.objectID,
        )?.shouldRemoveData

        if (!shouldSave) {
          // Remove if resigned, or remove from saved listings/mark for data removal
          const resigned = resignedListingData.find(
            (resignedData) => resignedData?.objectID === listing.objectID,
          )
          if (resigned) {
            appDispatch(setShouldRemoveResigned(resigned.objectID))
          }

          appDispatch(removeSelectedListing(listing.objectID))

          if (!shouldRemoveData) {
            appDispatch(setShouldRemoveData(listing.objectID))
          }
        } else if (shouldSave) {
          // GTM tracking - add favorite
          pushToDataLayer('favoriteListing', {
            listingId: listing.objectID,
            displayOption: containerDeps.displayOption,
            loginStatus: false,
          })

          // Add listing/remove mark for data removal
          appDispatch(setShowFavoriteNotification(true))
          appDispatch(
            addSelectedListing({
              objectID: listing.objectID,
              Headline: listing?.Headline ?? '',
              ImageUrl: listing?.ImageUrl ?? '',
            }),
          )

          if (shouldRemoveData) {
            appDispatch(clearShouldRemoveData(listing.objectID))
          }
        }

        handleToast(shouldSave, listing.objectID ?? '')
        handleAnimations(shouldSave)
      }
    },
    [
      appDispatch,
      containerDeps.displayOption,
      handleAnimations,
      handleToast,
      resignedListingData,
      selectedListingsData,
    ],
  )

  const handleClick = (listing: HitCombinedListing) => {
    const shouldSave = !selectedListings?.find(
      (selected) => selected.objectID === listing?.objectID,
    )
    if (!saveAsGuest && !session && shouldSave) {
      appDispatch(setLoginPromptModalOrigin(containerDeps.displayOption ?? ''))

      // GTM tracking - view create account prompt (modal)
      pushToDataLayer('createAccountPrompt', {
        displayOption: containerDeps.displayOption,
      })

      appDispatch(setShowLoginPromptModal(true))

      // Capture id of first listing clicked before saveAsGuest or login/sign up
      if (listing?.objectID !== persistedFirstFavorite) {
        appDispatch(setFirstFavoriteId(listing?.objectID ?? ''))
      }
      appDispatch(setSelectedFavorite(trackedEventData))
    } else if (session) {
      handleLoggedInFavoriting(shouldSave, listing)
    } else {
      handleGuestFavoriting(shouldSave, listing)
      appDispatch(trackFavoriteEvent(trackedEventData))
    }
  }

  // Save the firstFavoriteId's listing to favorites directly after saveAsGuest is chosen
  useEffect(() => {
    if (
      saveAsGuest &&
      persistedFirstFavorite &&
      listing?.objectID &&
      persistedFirstFavorite === listing?.objectID
    ) {
      handleGuestFavoriting(true, listing)
      appDispatch(setFirstFavoriteId(''))
    }
  }, [
    appDispatch,
    saveAsGuest,
    persistedFirstFavorite,
    listing,
    handleGuestFavoriting,
  ])

  return (
    <>
      <button
        className={`${style.favoriteListingButton} ${
          containerDeps.containerStyle
        } ${!isSelected ? style.unselected : ''}`}
        onClick={(e) => {
          e.preventDefault()
          handleClick(listing)
        }}
      >
        {isSelected ? (
          <HeartFilled
            className={`${heartTimeout.isRunning ? style.pulsateFwd : ''}`}
            height="1.25rem"
            title="Favorited"
            width="1.25rem"
          />
        ) : (
          <HeartEmpty className={style.emptyHeart} title="Favorite" />
        )}
        {container === 'detailOverview' && width > 600 ? (
          <span
            className={`${saveTextTimeout.isRunning ? style.fadeOutAndIn : ''}`}
          >{`${isSelected ? 'Saved!' : 'Save'}`}</span>
        ) : null}
      </button>
    </>
  )
}

export default FavoriteButton
