import React, { CSSProperties } from 'react'
import classNames from 'classnames'

type StarProps = {
  fillId: string
  changeRating?: (...args: any[]) => void
  hoverOverStar?: (...args: any[]) => void
  unHoverOverStar?: (...args: any[]) => void
  isStarred: boolean
  isPartiallyFullStar: boolean
  isHovered: boolean
  hoverMode: boolean
  isCurrentHoveredStar: boolean
  isFirstStar: boolean
  isLastStar: boolean
  starDimension: string
  starSpacing: string
  starHoverColor: string
  starRatedColor: string
  starEmptyColor: string
  gradientPathName: string
  svgIconPath: string
  svgIconViewBox: string
}

const Star: React.FC<StarProps> = ({
  fillId,
  changeRating,
  hoverOverStar,
  unHoverOverStar,
  isStarred,
  isPartiallyFullStar,
  isHovered,
  hoverMode,
  isCurrentHoveredStar,
  isFirstStar,
  isLastStar,
  starDimension,
  starSpacing,
  starHoverColor,
  starRatedColor,
  starEmptyColor,
  svgIconPath,
  svgIconViewBox,
}) => {
  const starContainerStyle: CSSProperties = {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: isFirstStar ? undefined : starSpacing,
    paddingRight: isLastStar ? undefined : starSpacing,
    cursor: changeRating ? 'pointer' : undefined,
  }

  const starSvgStyle: CSSProperties = {
    width: starDimension,
    height: starDimension,
    transition: 'transform .2s ease-in-out',
    transform: isCurrentHoveredStar ? 'scale(1.1)' : undefined,
  }

  let fill: string
  if (hoverMode) {
    if (isHovered) fill = starHoverColor
    else fill = starEmptyColor
  } else {
    if (isPartiallyFullStar) fill = `url('#${fillId}')`
    else if (isStarred) fill = starRatedColor
    else fill = starEmptyColor
  }

  const pathStyle: CSSProperties = {
    fill: fill,
    transition: 'fill .2s ease-in-out',
  }

  const starClasses = classNames({
    'widget-svg': true,
    'widget-selected': undefined,
    'multi-widget-selected': isPartiallyFullStar,
    'hovered': isHovered,
    'current-hovered': isCurrentHoveredStar,
  })

  return (
    <div
      className="star-container"
      onClick={changeRating}
      onMouseEnter={hoverOverStar}
      onMouseLeave={unHoverOverStar}
      style={starContainerStyle}
    >
      <svg
        className={starClasses}
        style={starSvgStyle}
        viewBox={svgIconViewBox}
      >
        <path className="star" d={svgIconPath} style={pathStyle} />
      </svg>
    </div>
  )
}

export default Star
